<template>
    <blogs-module-component/>
</template>

<script>
    import BlogsModuleComponent from "@/components/admin/modules/BlogsModuleComponent";
    
    export default {
        name: "BlogsModule",
        components: {BlogsModuleComponent},
        title: "Gestión de Blogs | Turismo BC",
    }
</script>

<style scoped>

</style>